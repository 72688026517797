import BackendService from './BackendService';

export default class RuleService extends BackendService {

    constructor() {
        super();
    }
    createRule(rule) {
        return this.httpCall('rule',{
            method: 'POST',
            data: rule,
        });

    }
    updateRule(rule) {
       //console.log('contrato, con: ', contract)
        return this.httpCall('rule/'+rule._id,{
            method: 'PUT',
            data: rule,
        });
    }
    toggleStatus(_id){
        return this.httpCall('rule/'+_id+'/toggle',{
            method: 'PUT',
        });
    }

    deletetRule(rateConfig_id) {
        return this.httpCall('rate-config/'+rateConfig_id,{
            method: 'delete'
        });
    }


    getRuleId(rule_id) {

        return this.httpCall('rule/'+rule_id);

        //return this.httpCall('property' + property_id + '/users');
    }


    getRules(params) {
        //console.log('get rooms');

        return this.httpCall('rules',{
            params: params,
        });
    }




    getRateConfig(parameters) {
        return this.httpCall('rates-config',{
            params: parameters,
        });
    }





}
