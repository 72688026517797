

import swal from 'sweetalert2'
export default class RateplanFunctions  {



    formatMoney(value){
        value = Number(value)

        return this.numberWithCommas(value.toFixed(2));
    }

    gapFunction(base, value, link_type){
        let result = 0;
        base = Number(base);
        value = Number(value);
        //console.log('gapFunction', base, value, link_type)

        switch (link_type) {
            case '+$':
                result = (base + value);
                break;

            case '-$':
                result = (base - value);
                break;

            case '-%':
                result = (base - (base/100*value));

                break;
            case '+%':
                result = (base + (base/100*value));
                break;
            case '=':
                result = value;
                break;
            default:
                result = 0;
                break;
        }
        return result

    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    gapOnly(base, value, link_type){
        let result = 0;
        base = Number(base);
        value = Number(value);

        switch (link_type) {
            case '+$':
                result = (base + value);
                break;

            case '-$':
                result =  (base - value);
                break;

            case '-%':
                result =  (base - (base/100*value));

                break;
            case '+%':
                result =  (base + (base/100*value));
                break;
            case '=':
                result = value;
                break;
            default:
                result = 0;
                break;
        }
        return result

    }
    validadorCustom(fields, type){
        let booleanStatus = false;
        fields.forEach(field=>{
            if(type === 'string'){
                field = field+'';
                if(field==='null' ||  field==='' || field.trim().length === 0 ){
                    booleanStatus = true ;
                }
            }
            if(type==='number'){
                if(isNaN(parseFloat(field)) || !isFinite(field)){
                    booleanStatus = true ;
                }
            }
        });
        return booleanStatus;
    }


}
