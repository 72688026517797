<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('revenue.new.title')}}</h4>
                <breadcrumb>
                    <breadcrumb-item v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}"  class="color-blue-var" >{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                    <card class="mt-2"  v-loading="screenLoading"
                          element-loading-lock="true"
                          :element-loading-text="screenText"
                          element-loading-spinner="el-icon-loading">
                    <div>
                        <div class="col-sm-12">
                            <div class="box-inventory ">

                                <div class="form-preview  w-border">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle mb-2">{{$t('revenue.form.name')}}</label>
                                            </div>
                                            <div class="group-edit"  v-show="showBox([2, 3, 4])">
                                                <a class="a-right r-20 type-button" @click="info_page.index_page=1"> <i class="fa fa-check"></i></a>
                                                <a class="a-right type-text" @click="info_page.index_page=1"> Editar</a>
                                            </div>
                                            <div class="resumen-item" v-show="info_page.index_page>1">
                                                <span>{{revenue.name}}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12"  v-show="showBox([1])"  >

                                            <div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          style="width: 50%"
                                                          placeholder=""
                                                          v-model="revenue.name"
                                                          @input="stepsValidate('name')"
                                                >
                                                </fg-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="info_page.index_page===1">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-2">
                                                <button type="button" class="add-item btn-default" @click="checkAdvance('page_2')" :class="info_page.buttons.name?'':'inactive'">Siguiente</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-preview w-border" v-show="showBox([3, 4])">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="l-title mb-2">Seleccionar fechas</label>
                                            <span class="brl text-normal text-capitalize">{{calendar_info.min}} - {{calendar_info.max}}</span>
                                            <span class="brl text-normal">Se actualizarán {{revenue.dates.length}} {{revenue.dates.length===1?'día':'días'}} </span>
                                            <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                            <a class="a-right type-text" @click="info_page.index_page=2"> Editar</a>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-preview w-border" v-show="showBox([4])" v-if="info_page.rateplans.length">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="l-title">Contratos</label>
                                            <div v-for="rateplan in info_page.rateplans"  >

                                                <label class="mb-1" v-if="rateplan.isModified">{{rateplan.name}}</label>
                                                <div v-for="rateplan_link in rateplan.rateplan_links" v-if="rateplan_link.isModified">
                                                    <label class="mb-0"><span class="fa fa-link mr-2"> </span>{{rateplan_link.name}} </label>
                                                </div>
                                            </div>
                                            <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                            <a class="a-right type-text"  @click="info_page.index_page=3"> Editar</a>
                                        </div>
                                    </div>

                                </div>





                                <div class="form-dates w-border" v-show="showBox([2])">
                                    <div class="box-dates  mt-3">
                                        <div class="row ">
                                            <div class="col-md-7">
                                                <label class="subtitle">Seleccionar fechas</label>
                                                <fg-input>
                                                    <el-date-picker v-model="calendar_info.date_range" type="daterange"
                                                                    placeholder="Inicio"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1"
                                                                    range-separator="a"
                                                                    start-placeholder="Fecha inicio"
                                                                    end-placeholder="Fecha fin"
                                                    >
                                                    </el-date-picker>
                                                </fg-input>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="box-days">
                                                    <div class="form-check">
                                                        <label for="lunes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="lunes_d"
                                                                   v-model="calendar_info.week[1]">
                                                            <span class="form-check-sign"></span>
                                                            Lun.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="martes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="martes_d" v-model="calendar_info.week[2]">
                                                            <span class="form-check-sign"></span>
                                                            Mar.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="miercoles_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="miercoles_d" v-model="calendar_info.week[3]">
                                                            <span class="form-check-sign"></span>
                                                            Mie.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="jueves_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="jueves_d" v-model="calendar_info.week[4]">
                                                            <span class="form-check-sign"></span>
                                                            Jue.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="viernes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="viernes_d" v-model="calendar_info.week[5]">
                                                            <span class="form-check-sign"></span>
                                                            Vie.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="sabado_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="sabado_d" v-model="calendar_info.week[6]">
                                                            <span class="form-check-sign"></span>
                                                            Sab.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="domingo_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="domingo_d" v-model="calendar_info.week[0]">
                                                            <span class="form-check-sign"></span>
                                                            Dom.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="box-button mb-5 mt-3">
                                                    <button type="button" class="add-item btn-default" @click="addRangeCalendar">
                                                        Añadir al calendario
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="dates-manually">
                                        <div class="row">
                                            <label class="l-title">Ajustes manuales</label>

                                            <div class="box-calendar">


                                                <div class="item-month"
                                                     v-for="(month_s, idsx) in calendar_info.monthsHead">
                                                    <div class="line-week lw-head">
                                                        <span class="fa fa-chevron-left sp-icon"
                                                              :class="(info_page.btn_back_status)?'':'n-visible'"
                                                              v-if="idsx===0" @click="updDate('minus')"></span>
                                                        <label class="calendar-title wout-icon  color-blue-var"
                                                               :class="checkClass(idsx)" @click="addMonth(month_s)">{{nameMonth(month_s)}}</label>
                                                        <span class="fa fa-chevron-right sp-icon" v-if="idsx===2"
                                                              @click="updDate('plus')"></span>
                                                    </div>


                                                    <div class="line-week lw-head color-blue-var">
                                                        <div class="item-7" data-key="0">D</div>
                                                        <div class="item-7" data-key="1">L</div>
                                                        <div class="item-7" data-key="2">M</div>
                                                        <div class="item-7" data-key="3">M</div>
                                                        <div class="item-7" data-key="4">J</div>
                                                        <div class="item-7" data-key="5">V</div>
                                                        <div class="item-7" data-key="6">S</div>
                                                    </div>

                                                    <div class="line-week" v-for="day_s in checkMonth(month_s)">
                                                        <div class="item-7" @click.capture="addCalendar(day_s[0])"><span
                                                                :class="checkDay(day_s[0])">{{dayCalendar(day_s[0])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[1])"><span
                                                                :class="checkDay(day_s[1])">{{dayCalendar(day_s[1])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[2])"><span
                                                                :class="checkDay(day_s[2])">{{dayCalendar(day_s[2])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[3])"><span
                                                                :class="checkDay(day_s[3])">{{dayCalendar(day_s[3])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[4])"><span
                                                                :class="checkDay(day_s[4])">{{dayCalendar(day_s[4])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[5])"><span
                                                                :class="checkDay(day_s[5])">{{dayCalendar(day_s[5])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[6])"><span
                                                                :class="checkDay(day_s[6])">{{dayCalendar(day_s[6])}}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-md-12">
                                                <div class="box-legends">
                                                    <label>
                                                        <span class="example-picker"></span>Fechas seleccionadas
                                                    </label>
                                                    <label class="right-t color-blue" @click="resetCalendar">
                                                        Restablecer
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="box-button mb-4">
                                                    <button type="button" class="add-item btn-default"
                                                            :class="info_page.buttons.dates?'':'inactive'"
                                                            @click="orderDate">Siguiente
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="form-updates w-border" v-show="showBox([3])">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">Seleccionar contratos</label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-check mt-3">
                                                <label for="all_types" class="form-check-label">
                                                    <input class="form-check-input" type="checkbox" id="all_types"
                                                           v-model="info_page.all_types"
                                                           @change="changeTypeFilter('all')">
                                                    <span class="form-check-sign"></span>Seleccionar todos los contratos

                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row pl-4">
                                        <div class="col-md-3 pr-0" v-for="type_ in info_page.contract_types"
                                             v-if="type_.status">
                                            <div class="form-check">
                                                <label :for="type_._id" class="form-check-label">

                                                    <input class="form-check-input" type="checkbox" :id="type_._id"
                                                           :value="type_._id" v-model="type_.filter"
                                                           @change="changeTypeFilter('item', type_._id, type_.filter)">
                                                    <span class="form-check-sign"></span>{{type_.name}}

                                                </label>

                                                <!--<label :for="type_._id+'r'" class="form-check-label ml-4">

                                                    <input class="form-check-input" type="checkbox" :id="type_._id+'r'"
                                                           :value="type_._id" v-model="type_.links_filter">
                                                    <span class="form-check-sign"></span><span v-html="info_page.links_rateplans"></span>

                                                </label>-->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12" v-for="(rateplan, idx) in info_page.rateplans">
                                            <!--<div class="group-upd color-primary">
                                                <label>
                                                    &lt;!&ndash;<a v-tooltip.top-center="formatText(room.isBaseLink)"  v-if="room.isBaseLink.length"
                                                    ><span class="fa fa-star"> </span></a>&ndash;&gt;


                                                    {{rateplan.name}}
                                                </label>
                                            </div>-->
                                            <div class="form-check">
                                                <label :for="rateplan._id+idx" class="form-check-label">
                                                    <input class="form-check-input" type="checkbox"
                                                           :id="rateplan._id+idx" v-model="rateplan.isModified"
                                                           @change="stepsValidate('rateplans')"
                                                          >
                                                    <span class="form-check-sign"></span>{{rateplan.name}}
                                                    <a v-tooltip.top-center="'Contrato con habitaciones vinculadas.'"  v-if="rateplan.isBaseLink"
                                                    ><i class="fa fa-link"></i></a>
                                                </label>


                                                <div class="ml-3" v-for="rateplanLink in rateplan.rateplan_links">
                                                    <label :for="rateplanLink._id+idx" class="form-check-label">
                                                        <input class="form-check-input" type="checkbox"
                                                               :id="rateplanLink._id+idx" v-model="rateplanLink.isModified"
                                                               @change="stepsValidate('rateplans')"
                                                        >
                                                        <span class="form-check-sign"></span><i class="fa fa-link"></i> {{rateplanLink.name}}

                                                    </label>


                                                </div>




                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-4">
                                                <button type="button" class="add-item btn-default"
                                                        :class="info_page.buttons.rateplans?'':'inactive'"
                                                        @click="checkAdvance('rateplans')">Siguiente
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-updates w-border" v-show="showBox([4])">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">Condiciones</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="contain-info">
                                            <div class="row pt-3" v-for="(rateplan,  idxKey) in revenue.rateplans">
                                                <div class="col-3">
                                                    <label class="mb-0">{{rateplan.name}}</label>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span>Condición 1</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span>Condición 2</span>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <span>Condición 3</span>
                                                </div>
                                                <div class="col-12">
                                                    <div class="item-room" v-for="(config_rule, idxN) in rateplan.rate_config_rules">
                                                        <div class="row">
                                                            <div class="item-info">
                                                                <div class="room-info">
                                                                    <el-select class="select-default"  size="large" v-model="config_rule.rate_config_id"
                                                                               :class="checkError(config_rule.rate_config_id, 'string')?'hasError':''"
                                                                               @change="checkDifferences(); changeRoom($event, rateplan.roomsAvailables, config_rule)"
                                                                    >
                                                                        <el-option v-for="(roomList, idxKe) in rateplan.roomsAvailables" :value="roomList.rate_config_id"
                                                                                   :label="roomList.name" v-show="roomList.status">
                                                                        </el-option>
                                                                        <!--<el-option v-for="(roomList, idxKe) in rateplan.roomsLinks" :value="roomList._id"
                                                                                   :label="roomList.name">
                                                                        </el-option>-->
                                                                    </el-select>
                                                                    <div class="item-minus ml-2">
                                                                        <i class="fa fa-minus c-point"  @click="deleteRoom(idxKey, idxN)" v-show="rateplan.rate_config_rules.length>1" v-tooltip.bottom="'Eliminar habitación'"></i>
                                                                    </div>
                                                                </div>

                                                                <div class="condition-info" >
                                                                    <div class="item-condition" v-for="(condition, idxCon) in config_rule.conditions">
                                                                        <div class="inside-condition">
                                                                            <label>Ventas (CN)</label>
                                                                            <fg-input type="text"
                                                                                    class="input-default"
                                                                                      :class="checkError(condition.room_nights, 'number')?'hasError':''"
                                                                                    v-model="condition.room_nights">
                                                                            </fg-input>
                                                                        </div>
                                                                        <div class="inside-condition">
                                                                            <el-select class="select-default" v-if="config_rule.configList.length>1" size="large"
                                                                                       v-model="condition.rate_type" @change="changeBar($event, condition, config_rule.configList)">
                                                                                <el-option v-for="(option, idxKe) in config_rule.configList" :value="option.name"
                                                                                           :label="option.name">
                                                                                </el-option>
                                                                            </el-select>
                                                                            <el-select class="select-default class-disabled"  disabled v-else size="large"
                                                                                       v-model="condition.rate_type">
                                                                                <el-option v-for="(option, idxKe) in config_rule.configList" :value="option.name"
                                                                                           :label="option.name">
                                                                                </el-option>
                                                                            </el-select>
                                                                        </div>
                                                                        <div class="inside-condition short">
                                                                            <label>Tarifa</label>
                                                                            <fg-input type="text"
                                                                                      :class="checkError(condition.price, 'number')?'hasError':''"
                                                                                    class="input-default"
                                                                                    v-model="condition.price"
                                                                                    :disabled="!condition.editable"
                                                                            >
                                                                            </fg-input>
                                                                        </div>
                                                                        <a class="btn-corner" v-if="idxCon>0" @click="deleteCondition(idxKey, idxN, idxCon)" v-tooltip.top="'Eliminar condición'"> <i class="fa fa-minus-circle"></i></a>

                                                                    </div>
                                                                    <div class="item-condition empty" v-show="config_rule.conditions.length < 3 " @click="addCondition(idxKey, idxN)" v-tooltip.top="'Agregar condición'">
                                                                        <span>Agregar condición</span>
                                                                        <a class="btn-plus"  >
                                                                            <i class="fa fa-plus-circle"  ></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <!--<a class="btn-plus"  v-show="room.length < room.roomsAvailables.length + room.roomsLinks.length " >-->
                                                            <a class="btn-plus c-point"  v-show="rateplan.rate_config_rules.length < rateplan.roomsAvailables.length" >
                                                                <span class="add-text" @click="addRoom(idxKey)" v-tooltip.bottom="'Agregar habitación'">+ Agregar habitación</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>

                                    <!--<div class="row">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-4">
                                                <button type="button" class="add-item btn-default"  :class="info_page.buttons.page_3?'':'inactive'" @click="toActualizaciones()">Vista previa de actualizaciones</button>
                                            </div>
                                        </div>
                                    </div>-->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-check mt-5 mb-3 pl-2">
                                                <label for="test" class="form-check-label check-admin">
                                                    <input class="form-check-input" type="checkbox" id="test"
                                                           v-model="revenue.status">
                                                    <span class="form-check-sign"></span> Activar al guardar
                                                </label>
                                            </div>

                                            <div class="col-12 custom-error pl-3" v-if="show_errors">
                                                <label class="text-danger">{{show_text}}</label>
                                            </div>

                                            <div class="col-12">
                                                <div class="form-send">
                                                    <div class="box-button mb-5">
                                                        <button type="button" class="add-item btn-success" @click="sendInfo()">Enviar actualizaciones</button>
                                                        <button type="button" class="add-item second btn-danger"  @click="cancelAction()">Cancelar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>

    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem, Radio} from 'src/components/index'
    import {Select, Option, DatePicker} from 'element-ui'
    //import users from 'src/pages/Dashboard/Tables/users'
    import moment from 'moment';
    //import {TaskQueue} from 'cwait';

    import PropertyService from '../../../js/services/PropertyService';
    import RoomService from '../../../js/services/RoomService';
    import RateService from '../../../js/services/RateService';
    import ContractService from '../../../js/services/ContractService';
    import RuleService from '../../../js/services/RuleService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';


    import EventBus from "../../../js/helpers/EventBus";
    import {vueTopprogress} from 'vue-top-progress'
    import RateplanFunctions from '../../../js/functions/RateplanFunctions';

    const propertyService = new PropertyService();
    const rateService = new RateService();
    const contractService = new ContractService();
    const roomService = new RoomService();
    const ruleService = new RuleService();
    const rateplanFuntions = new RateplanFunctions;
    import {mapGetters} from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    export default {
        components: {
            [DatePicker.name]: DatePicker,
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress,
            [Option.name]: Option,
            [Select.name]: Select,
            Radio
        },
        computed: {
            ...mapGetters(["currentUser","selected_property"]),

        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                breadcrumbs: [
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('revenue.index.title'),
                        path: '/admin/revenues',
                        type: '',
                    },

                    {
                        name: this.$t('revenue.new.header'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page: {
                    original_info:{},
                    section_name: 'rule',
                    property_id: {},
                    index_page: 1,
                    tab_lang: 'es',
                    daysName: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
                    langs: [{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    }, {
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    btn_back_status: false,

                    all_types: false,
                    links_rateplans: 'Contratos vinculados<br> Cargar de manera individual',
                    contract_types: [
                        {
                            _id: 1,
                            name: 'Contratos solo habitación (EP)',
                            status: false,
                            filter: false,
                            links_filter: false,
                        },
                        {
                            _id: 2,
                            name: 'Contratos habitación + desayuno (EP + Desayuno)',
                            status: false,
                            filter: false,
                            links_filter: false,
                        },
                        {
                            _id: 3,
                            name: 'Contrato todo incluido (AI)',
                            status: false,
                            filter: false,
                            links_filter: false,
                        }
                    ],
                    states_arrive: [
                        {
                            name: 'Sin cambios',
                            value: 0
                        },
                        {
                            name: 'Abierta',
                            value: 1
                        },
                        {
                            name: 'Cerrada',
                            value: 2
                        }
                    ],
                    rooms: [],

                    types_bar: [],
                    room_model: {
                        name: 'title',
                        value: '',
                        status: 0,
                    },
                    filter_options: [],
                    buttons: {
                        name: false,
                        dates: false,
                        rateplans: false,
                        page_3: false,
                    },

                    rateplans: [],
                },
                calendar_info: {
                    monthsHead: [],
                    selected: [],
                    min: '',
                    max: '',
                    availables_days: ['-1'],
                    date_range: ['', ''],
                    week: [1, 1, 1, 1, 1, 1, 1],
                },
                revenue:{
                    _id: null,
                    property_id: null,
                    name: [],
                    dates: [],
                    all_rateplans: true,
                    rateplans: [],
                    rate_config_rules: [],
                    status:true
                },

                auxObject: {},
                show_errors: false,
                show_text: '',
                dataSend: [],
                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    },
                },

            }
        },
        beforeCreate(){
            //location.reload();
        },
        created() {
            this.chargerItems(this.selected_property._id);
            moment.locale('es');
            //location.reload();
        },
        methods: {
            changeProperty() {
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if (this.$route.name === 'ResumeProperty') {
                        this.chargerItems(property_id);
                    }

                })
            },
            async chargerItems(property_id = null) {
                this.initLoading()
                this.info_page.property_id = property_id;
                this.revenue.property_id = property_id;
                let date_ = moment().locale('es');
                let date_end = moment().format('YYYY-MM-DD');
                let date_start = moment().format('YYYY-MM-DD');
                let relRoomRateplan = [];

                this.configLogs(property_id, this.info_page.section_name);

                let property = await propertyService.getPropertyId(property_id);
                property = property.data.data.property;
                let paymnt = {};

                let typesActives = [];


                paymnt = this.info_page.contract_types.find(payment => payment._id === 1);
                paymnt.status = property.plan_ep;
                // paymnt.filter = property.plan_ep;
                paymnt = this.info_page.contract_types.find(payment => payment._id === 2);
                paymnt.status = property.plan_epb;
                //paymnt.filter = property.plan_epb;
                paymnt = this.info_page.contract_types.find(payment => payment._id === 3);
                paymnt.status = property.plan_ai;
                // paymnt.filter = property.plan_ai;

                let rates = await rateService.getRatesTarYRest({
                    property: property_id,
                    from: date_start,
                    to: date_end,
                    rateplan_status: 1

                })


                let     params = {
                        property_id: property_id,
                        status: true,
                        expired: false
                    }

                let rateplans =  await contractService.getRateplanIndex(params);
                rateplans = rateplans.data.data.rateplans;

                rates = rates.data.data.rooms;

                let rateplanAlls = [];
                rateplans.forEach(async rateplan => {
                    if (rateplan.rateplan_link === undefined && rateplan.status === true)  {
                        let rateplanModel = {
                            _id: rateplan._id,
                            name: rateplan.name,
                            meal_plan: rateplan.meal_plan,
                            rateplan_links: [],
                            rooms: [{
                                _id: null,
                                conditions:[{
                                    qty: null,
                                    var: null,
                                    value: null,
                                    _id: null,
                                }]
                            }],
                            config_ids: [],
                            roomsAvailables: [],
                            roomsLinks: [],
                            isBaseLink: false,
                            ratesList: [],
                            isModified: false,
                            isShow: false,
                            room_links: false,
                        }
                        rateplanAlls.push(rateplanModel)
                        rateplan.rates_list.forEach((element, idxBar)=>{
                                rateplanModel.ratesList.push({
                                'name': this.assigNameRack(idxBar),
                                'value': element,
                                'editable': false
                            })
                        })
                        rateplanModel.ratesList.push({
                            'name': 'Manual',
                            'value': null,
                            'editable': true
                        });


                    }
                });
                rateplans.forEach(async rateplan => {

                    if (rateplan.rateplan_link && rateplan.status === true)  {
                        let rateplanModel = {
                            _id: rateplan._id,
                            name: rateplan.name,
                            meal_plan: rateplan.meal_plan,
                            rooms: [{
                                _id: null,
                                conditions:[{
                                    qty: null,
                                    var: null,
                                    value: null,
                                    _id: null,
                                }]
                            }],
                            config_ids: [],
                            roomsAvailables: [],
                            roomsLinks: [],
                            isBaseLink: false,
                            ratesList: [{
                                'name': 'Manual',
                                'value': null,
                                'editable': true
                            }],
                            isModified: false,
                            isShow: false,
                            room_links: false,
                        }
                        let rateplanInArray = rateplanAlls.findIndex(element => element._id === rateplan.rateplan_link.rateplan)
                        rateplanAlls[rateplanInArray].rateplan_links.push(rateplanModel);
                    }
                });

                //console.log('rateplanAlls test', rateplanAlls);
                rates.forEach(async rateInfo => {





                        rateInfo.rates_plan.forEach(rateplan => {


                            if (rateplan.rateplanlink_data.length === 0 && rateplan.rateplan_data.status === true)  {

                                let rateplanInArray = rateplanAlls.findIndex(element => element._id === rateplan.rateplan_data._id)
                                let roomModel = {
                                    _id: rateInfo._id.room_id,
                                    name: rateInfo._id.name,
                                    rate_config_id: rateplan.rateconfig_id,
                                    linkInfo:null,
                                    status: true
                                }

                                if (rateplanInArray >= 0) {
                                    //console.log('rateplanAlls[rateplanInArray]', rateplanAlls[rateplanInArray]);
                                    //console.log('rateplanAlls[rateplanInArray]', rateplanAlls[rateplanInArray].name, rateplanInArray);
                                    if(rateplanAlls[rateplanInArray].room_links === false){
                                        rateplanAlls[rateplanInArray].room_links =  (rateplan.room_link!==undefined && rateplan.room_link!==null );
                                    }

                                    //console.log('rateplanAlls[rateplanInArray]', rateplan);
                                    rateplanAlls[rateplanInArray].rate_config_id = null;
                                    rateplanAlls[rateplanInArray].config_ids.push(rateplan.rateconfig_id);
                                    rateplanAlls[rateplanInArray].roomsAvailables.push(roomModel);

                                    //console.log('rateInfo', rateplan.room_link, rateplanAlls[rateplanInArray].room_links);
                                   /*if(rateplan.room_link){
                                        rateplanAlls[rateplanInArray].roomsLinks.push(roomModel);
                                    }else{
                                       rateplanAlls[rateplanInArray].roomsAvailables.push(roomModel);
                                    }*/

                                }
                            }else{

                                let rateplanInArray = rateplanAlls.findIndex(element => element._id === rateplan.rateplanlink_data[0].rateplan)
                                //console.log('***********************************vinculado', rateplan);

                                if (rateplanInArray >= 0) {
                                    let roomModel = {
                                        _id: rateInfo._id.room_id,
                                        name: rateInfo._id.name,
                                        rate_config_id: rateplan.rateconfig_id,
                                        status: true
                                    }
                                    let rateplanInArrayLink = rateplanAlls[rateplanInArray].rateplan_links.findIndex(element => element._id === rateplan.rateplan_data._id)
                                    if (rateplanInArray >= 0) {
                                        //console.log('-------------------------- idx', rateplanAlls, rateplanInArray);
                                        rateplanAlls[rateplanInArray].rateplan_links[rateplanInArrayLink].room_links =  false;
                                        rateplanAlls[rateplanInArray].rateplan_links[rateplanInArrayLink].rate_config_id = null;
                                        rateplanAlls[rateplanInArray].rateplan_links[rateplanInArrayLink].config_ids .push(rateplan.rateconfig_id);
                                        rateplanAlls[rateplanInArray].rateplan_links[rateplanInArrayLink].roomsAvailables.push(roomModel);

                                       /* if(rateplan.room_link){
                                            rateplanAlls[rateplanInArray].rateplan_links[rateplanInArrayLink].roomsLinks.push(roomModel);
                                        }else{
                                            //console.log('rateplanInArray', rateplanInArray, rateplan);
                                            rateplanAlls[rateplanInArray].rateplan_links[rateplanInArrayLink].roomsAvailables.push(roomModel);
                                        }*/
                                    }
                                    //console.log('rateplanInArrayLink', rateplanInArrayLink);
                                }
                            }
                            /*if(rateplan.rateplanlink_data.length > 0 && rateplan.rateplan_data.status === true){
                                rateplan.rateplanlink_data.forEach(rateplanLink=>{
                                    let idxRelacion = rateplanAlls.findIndex(elem => elem._id === rateplanLink.rateplan)
                                    if(idxRelacion>=0){
                                        rateplanAlls[idxRelacion].isBaseLink = true;
                                    }
                                });
                            }*/
                        });
                });
               //console.log('rateplanAlls', rateplanAlls)
                //this.$refs.topProgress.done()
                this.info_page.rateplans = rateplanAlls


                //this.rateplansLinks.values = rateplansLinks
                this.calendarDashboard(date_);
                this.checkRoute();
            },

            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

            checkRoute() {
                let rule_id = this.$route.params.id;

                if (this.$route.name === 'editRevenue') {
                    this.info_page.action = 'edit';
                    this.log_page.action = 1;
                    this.info_page.header = this.$t('revenue.edit.header');
                    this.info_page.button = this.$t('form.update');
                    this.breadcrumbs[this.breadcrumbs.length - 1].name = this.$t('revenue.edit.header');
                    this.updateData(rule_id);
                }else{
                    this.closeLoading();
                }

            },



            calculateRateTotal(value, type){
                let valueReturn = 0;
                valueReturn = value;
                return valueReturn;
            },

            getArraysIntersection(rules_config, config_ids){
                return  rules_config.filter(function(n) { return config_ids.indexOf(n.rate_config_id) !== -1;});
            },


            async updateData(rule_id){
                //console.log('update data');
                let ruleResponse = await ruleService.getRuleId(rule_id);
                ruleResponse = ruleResponse.data.data.rule;

                this.info_page.original_info = JSON.parse(JSON.stringify(ruleResponse))

                this.revenue._id = ruleResponse._id;
                this.revenue.name = ruleResponse.name;
                this.revenue.dates = ruleResponse.dates.map(item =>  moment(item, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                this.calendar_info.min = moment(ruleResponse.dates[0], 'YYYY-MM-DD').format('DD MMM YYYY');
                this.calendar_info.max = moment(ruleResponse.dates[ruleResponse.dates.length - 1], 'YYYY-MM-DD').format('DD MMM YYYY');
                this.revenue.status = ruleResponse.status;

                this.info_page.index_page = 4;


                let idxKey = 0;
                while (idxKey<this.info_page.rateplans.length){
                    let rateplan = this.info_page.rateplans[idxKey];
                    if(ruleResponse.rateplans.some(item => item === rateplan._id)){
                        rateplan.isModified = true;
                        let arrayConfigRules =  this.getArraysIntersection(ruleResponse.rate_config_rules, rateplan.config_ids);
                        arrayConfigRules.map(item => item.conditions.map(element => element.editable = (element.rate_type==='Manual')));


                        let roomsAvailables = await this.generateRateList(rateplan);
                        /*let room_finded = roomsAvailables.find(item => item.rate_config_id === event)
                        config_rule.configList = room_finded.ratesList;*/

                        arrayConfigRules.map(item => {
                            let room_finded = roomsAvailables.find(element => element.rate_config_id === item.rate_config_id)
                            item.configList = room_finded.ratesList;
                        });
                        //console.log('arrayConfigRules', arrayConfigRules);
                       //console.log('arrayConfigRules', arrayConfigRules, roomsAvailables)

                        let rateplanBase = {
                            name: rateplan.name,
                            _id: rateplan._id,
                            roomsAvailables:  roomsAvailables,
                            ratesList: rateplan.ratesList,
                            rate_config_rules:arrayConfigRules,
                            room_links: false,

                        }


                        if(rateplanBase.room_links === false){
                            rateplanBase.room_links =  (rateplan.room_link!==undefined && rateplan.room_link!==null );
                        }


                        this.revenue.rateplans.push(rateplanBase)



                    }
                    rateplan.rateplan_links.forEach(rateplan_link=>{
                        if(ruleResponse.rateplans.some(item => item === rateplan_link._id)){
                            rateplan_link.isModified = true;

                            let arrayConfigRules =  this.getArraysIntersection(ruleResponse.rate_config_rules, rateplan_link.config_ids);
                            arrayConfigRules.map(item => item.conditions.map(element => element.editable = (element.rate_type==='Manual')));

                            arrayConfigRules.map(item => item.configList = rateplan_link.ratesList);
                            //console.log('manual arrayConfigRules', arrayConfigRules);

                            rateplan_link.roomsAvailables.map(item=> item.ratesList = rateplan_link.ratesList);
                            let rateplanBase = {
                                name: rateplan_link.name,
                                _id: rateplan_link._id,
                                roomsAvailables: rateplan_link.roomsAvailables,
                                ratesList: rateplan_link.ratesList,
                                room_links: false,
                                rate_config_rules:arrayConfigRules

                            }
                            this.revenue.rateplans.push(rateplanBase)


                        }
                    });
                    idxKey++;
                }

                /*this.info_page.rateplans.forEach(rateplan=>{

                    //console.log('rule_id', rateplan);

                })*/
                this.checkDifferences();

                this.closeLoading();
                this.stepsValidate('name');
                this.checkButtonAvailable('dates');
                this.stepsValidate('rateplans');

            },

            /************           Calendario funciones                    ************/

            addRangeCalendar() {
                let array_dates = this.calendar_info.date_range;

                let date_selecteds = this.revenue.dates;
                let range_init = array_dates[0];
               ;

                let range_start = moment(array_dates[0]);
                let range_end = moment(array_dates[1]);

                let weeekdays =  this.calendar_info.week;

                while (range_start <= range_end) {

                    let status_day = weeekdays[range_start.day()];
                    if (status_day) {
                        let idx = date_selecteds.indexOf(range_start.format('YYYY-MM-DD'));
                        if (idx < 0) {
                            this.revenue.dates.push(range_start.format('YYYY-MM-DD'))
                        }
                    }
                    range_start.add(1, 'days');
                }
                this.checkButtonAvailable('dates');
                let date_end_now = moment(this.calendar_info.monthsHead[2], 'MM-YYYY').startOf('month');
                let date_start_new = moment(range_init)
               //console.log(date_start_new>date_end_now, date_start_new, date_end_now, 'stop');
                if(date_start_new>date_end_now){
                    this.calendarDashboard(date_start_new)
                    this.checkDateNow()
                }
            },
            addMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.revenue.dates;
                let array_result = [];

                while (start_of < daysMonth) {


                    let aux = start_of.format('YYYY-MM-DD')
                    let date_now = moment();
                    if (!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {

                        //console.log('es un dia disponible', aux)
                        let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                        if (idx < 0) {
                            array_result.push(start_of.format('YYYY-MM-DD'))
                        }
                    }

                    start_of.add(1, 'days')
                }
                //console.log('arr_r', array_result.length)

                if (array_result.length === 0) {
                    this.deleteMonth(month_s)
                } else {
                    this.revenue.dates = date_selecteds.concat(array_result)
                }

                this.checkButtonAvailable('dates');

            },
            deleteMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.revenue.dates;

                while (start_of < daysMonth) {

                    let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                    if (idx >= 0) {
                        date_selecteds.splice(idx, 1)
                    }
                    start_of.add(1, 'days')
                }
            },
            resetCalendar() {
                this.revenue.dates = [];
                this.checkButtonAvailable('dates');
                this.calendar_info.week = [1, 1, 1, 1, 1, 1, 1];
                this.calendar_info.date_range = ['', ''];
            },
            calendarDashboard(date_) {
                let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');
                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month];

            },
            dayCalendar(date) {
                let aux = '';
                if (date !== null) {
                    aux = moment(date, 'YYYY-MM-DD').format('D')
                }

                return aux
            },
            addCalendar(date) {
                let dates_Array = this.revenue.dates;
                if (date !== null) {


                    let aux = date
                    let date_now = moment();
                    if (!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {

                        //console.log('es un dia disponible')
                        let idx = dates_Array.indexOf(date);
                        if (idx >= 0) {
                            dates_Array.splice(idx, 1)

                        } else {
                            dates_Array.push(date);
                        }
                    }
                }
                this.checkButtonAvailable('dates');

                //console.log('dates_Array', dates_Array)
            },
            nameMonth(date) {
                return moment(date, 'MM-YYYY').format('MMMM')
            },
            checkDateNow() {

                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY').subtract(1, 'M').format('YYYY-MM-DD')
                /* let date_now = moment().format('YYYY-MM-DD');

                 let date_dashboard = parseInt(date.format('M'));
                 let month_now = parseInt(date_now.format('M'));*/
               //console.log(date, moment().isAfter(date, 'month'), 'test')
                if(moment().isAfter(date, 'month')) {
                    this.info_page.btn_back_status = false;
                } else {
                    this.info_page.btn_back_status = true;
                }
            },
            updDate(action) {
                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                if (action === 'plus') {
                    date.add(1, 'M')
                    this.calendarDashboard(date)

                } else if (action === 'minus') {
                    date.subtract(1, 'M')
                    this.calendarDashboard(date)
                }
                this.checkDateNow();

            },
            checkDay(date) {
                let dates_Array = this.revenue.dates;
                let response = '';
                let resp_status = '';

                if (date !== null) {
                    response = dates_Array.indexOf(date);
                    if (response >= 0) {
                        resp_status = 'select-day';
                    }

                    let aux = date
                    let date_now = moment();
                    if (moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {


                        resp_status = 'block-day';
                        //console.log('bloquear date', moment(aux).isSameOrAfter(date_now.format('YYYY-MM-DD'), 'day'))

                    }
                }
                return resp_status;
            },
            checkClass(idx) {
                let result_class = '';
                if (idx === 0) {
                    result_class += 'w-80';
                }
                else if (idx === 2) {
                    result_class += 'ml-20 w-80';
                }
                return result_class
            },
            checkMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let array_result = [];
                let array_model = [null, null, null, null, null, null, null];
                //console.log('month_s', start_of.week)

                while (start_of < daysMonth) {

                    array_model[start_of.day()] = start_of.format('YYYY-MM-DD');
                    if (start_of.day() === 6) {

                        array_result.push(array_model);
                        array_model = [null, null, null, null, null, null, null];
                    }

                    start_of.add(1, 'days')
                }

                array_result.push(array_model);
                //console.log('array_result', array_result)
                return array_result;

            },
            orderDate() {
                if (this.info_page.buttons.dates) {
                    let calendar_array = this.revenue.dates;
                    //console.log('orderDate', )
                    calendar_array = calendar_array.sort()

                    this.calendar_info.min = moment(calendar_array[0]).format('DD MMM YYYY');
                    this.calendar_info.max = moment(calendar_array[calendar_array.length - 1]).format('DD MMM YYYY');

                    this.info_page.index_page = 3;
                    this.checkDaysAvailables()
                }
            },
            checkDaysAvailables() {
                //console.log('checkDaysAvailables')
                let dates_Array = this.revenue.dates;
                let array_aux = [];

                dates_Array.forEach(item => {

                    let result = array_aux.indexOf(moment(item).day());
                    if (result < 0) {
                        array_aux.push(moment(item).day())
                    }
                })

                this.calendar_info.availables_days = array_aux.sort()

                let avai_days = this.calendar_info.availables_days;
                let ky_sunday = avai_days.indexOf(0)
                if (ky_sunday >= 0) {
                    avai_days.splice(ky_sunday, 1);
                    avai_days.push(0);
                }
                //console.log('this.calendar_info.availables_days', this.calendar_info.availables_days)

            },

            checkButtonAvailable(type_) {
                switch (type_) {

                    case 'dates':
                        if (this.revenue.dates.length) {
                            this.info_page.buttons.dates = true;
                        } else {
                            this.info_page.buttons.dates = false;
                        }
                        break;
                    case 'page_2':
                        /*if(this.contracts.modified.length) {
                            this.info_page.buttons.page_2 = true;
                        }else{
                            this.info_page.buttons.page_2 = false;
                        }*/

                        break;
                    case 'page_3':

                        break;

                }
            },


            validadorCustom(fields, type){
                let booleanStatus = false;
                fields.forEach(field=>{
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                        }
                    }
                });

                return booleanStatus;

            },



            checkError(value, type){
              let errorReturn = false;
              return this.show_errors && rateplanFuntions.validadorCustom([value], type)
            },






            toActualizaciones(){
                if(this.info_page.buttons.page_3 ){
                    this.info_page.index_page=4;
                }
            },
            validateCustom() {
                let error_return = false;
                let error_text = '';



                let revenueInfo = JSON.parse(JSON.stringify(this.revenue));
                revenueInfo.rateplans.forEach(rateplan=>{
                    rateplan.rate_config_rules.forEach(rule_config=>{
                        rule_config.conditions.forEach(condition=>{

                            if(rateplanFuntions.validadorCustom([condition.price], 'number')){
                                error_return = true;
                                error_text = 'Tarifas pendientes por completar'
                            }
                            if(rateplanFuntions.validadorCustom([condition.room_nights], 'number')){
                                error_return = true;
                                error_text = 'Ventas pendientes por completar'
                            }

                        })
                        if(rateplanFuntions.validadorCustom([rule_config.rate_config_id], 'string')){
                            error_return = true;
                            error_text = 'Habitaciones pendientes por seleccionar'
                        }

                    });
                });

                this.show_errors = error_return;
                this.show_text = error_text;

                return error_return;

            },

            async  sendInfo(){
                if(this.validateCustom()){
                    return;
                }
                let revenueInfo = JSON.parse(JSON.stringify(this.revenue));
                let revenueData = {
                    _id: revenueInfo._id,
                    property_id: revenueInfo.property_id,
                    name: revenueInfo.name,
                    dates: revenueInfo.dates,
                    status: revenueInfo.status,
                    rateplans: [],
                    rate_config_rules: [],
                    all_rateplans: true,
                };
                let idxKey = 0;

                revenueInfo.rateplans.forEach(rateplan=>{
                    revenueData.rateplans.push(rateplan._id);
                    rateplan.rate_config_rules.forEach(rule_config=>{
                        rule_config.index = idxKey;
                        idxKey++;
                        rule_config.conditions.forEach(condition=>{
                            delete condition.editable;
                        })
                        revenueData.rate_config_rules.push(rule_config);
                    });
                });
               //console.log('revenueData', revenueData);
                if(revenueData._id!==null){
                    //conso
                    ruleService.updateRule(revenueData).then(async response=>{
                       //console.log('revenueData response', response);
                        response = response.data.data.rule;
                        let ruleResponse = await ruleService.getRuleId(response._id);
                        let info_nueva = ruleResponse.data.data.rule;
                        let info_original = this.info_page.original_info;
                      
                        let changes = logFunctions.cheackProp(info_original, info_nueva, this.info_page.section_name)
                        
                        if(changes.length){
                            await this.createLog(revenueData._id, 1, changes);
                        }
                        this.showSwal(this.$t('rate.dashboard.msg'));;
                    });
                }else{

                    ruleService.createRule(revenueData).then(async response=>{
                       //console.log('revenueData response', response);
                        let newRespose= response.data.data.rule;
                        await this.createLog(newRespose._id, 0)
                        this.showSwal(this.$t('rate.dashboard.msg'));
                    });
                }




                /*let configsNews = this.dataRateconfigs;
                let formatRate = [];

                swal({
                    title: 'Actualizando tarifas y restricciones',
                    html:'<img src="/static/img/loading.svg" style="width: 100px"/>'
                    ,
                    showConfirmButton: false,
                    buttonsStyling: false
                })
                //console.log('configsNews', configsNews)
                configsNews.forEach(itemConfig=>{
                    let dates_array = [];
                    let day_number = itemConfig.day_number;
                    if(day_number==='-1'){
                        dates_array = this.calendar_info.selected;
                    }else{
                        let array_aux = this.calendar_info.selected;
                        array_aux.forEach(dateItem=>{
                            let itemDay = moment(dateItem, 'YYYY-MM-DD').day()
                            if(itemDay===day_number){
                                dates_array.push(dateItem)
                            }
                        })
                    }
                    dates_array.forEach(dateSelected=>{
                        itemConfig.dataRooms.forEach(room=>{
                            room.rateplans.forEach(rateplan=>{
                                let modelRateConfig ={
                                    rate_config: rateplan.rateconfig_id,
                                    date: dateSelected,
                                    value: rateplan.ratesnInfo.base_rate
                                };

                                formatRate.push(modelRateConfig);
                            })
                        });
                    })
                })
                console.log('en construccion', this.calendar_info.min);

                await rateService.arrayRates(formatRate).then(response=>{
                    this.showSwal(this.$t('rate.dashboard.msg'));;
                });*/


            },
            cancelAction(){
                this.$router.push({
                    name: 'Revenues'
                });
            },

            /***************             Funciones generales ********************/

            showBox(array_obj) {
                let view_page = false;
                let page_now = this.info_page.index_page;

                let stats = array_obj.indexOf(page_now);
                if (stats >= 0) {
                    view_page = true;
                }
                return view_page;
            },
            testLog(test) {
                console.log('test log', test)
            },
            assigNameRack(idxk){
                let acomp = '';

                if(idxk===0){

                    acomp = 'Rack';
                }else if(idxk===1){
                    acomp = 'Bar';
                }else{
                    acomp = 'Bar '+((idxk));
                }
                return acomp;
            },
            showSwal(msg) {

                swal({
                    title: this.$t('rate.dashboard.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(() => {
                    this.$router.push({
                        name: 'Revenues',
                    })
                    /*this.$router.push({
                        name: 'Rates'
                    });*/
                });
            },
            formatText(isBaseLink){
                let stringReturn = 'Habitación base de contrato: ';

                isBaseLink.forEach((item, idx)=>{
                    //console.log(item);
                    if(idx<1){
                        stringReturn += ' '+item;
                    }else{
                        stringReturn += ', '+item;
                    }

                })
                return stringReturn;

            },

            async checkAdvance(page){
                switch (page) {
                    case 'page_2':
                        if(this.info_page.buttons.name){
                            this.info_page.index_page=2;
                        }

                        break;

                    case 'page_3':
                        if(this.info_page.buttons.booking){
                            this.info_page.index_page=3;
                        }

                        break;
                    case 'rateplans':


                        if(this.info_page.buttons.rateplans){
                            this.initLoading()
                            this.info_page.index_page=4;
                            let rate_config_rules = [];
                            let rateplanArrays = [];

                            let rateplansEdit = JSON.parse(JSON.stringify(this.revenue.rateplans));
                            let keyIdx = 0;

                             while (keyIdx<this.info_page.rateplans.length){
                                let rateplan = this.info_page.rateplans[keyIdx]

                                let rateplanModel = {
                                    rate_config_id: null,
                                    index: 0,
                                    configList: [],
                                    conditions: [
                                        {
                                            editable: true,
                                            room_nights: '',
                                            rate_type: rateplan.ratesList.length>1?null:'Manual',
                                            price: ''
                                        }
                                    ]
                                };
                                let findRateplan = rateplansEdit.find(item=> item._id === rateplan._id);
                                if(findRateplan){
                                    rateplanArrays.push(findRateplan);
                                    if(rateplan.isModified===false){
                                        let idxRateplan = rateplansEdit.findIndex(item=> item._id === rateplan._id);
                                        rateplanArrays.splice(idxRateplan, 1);
                                    }


                                }else{
                                    let rateplanBase = {
                                        name: rateplan.name,
                                        _id: rateplan._id,
                                        roomsAvailables: rateplan.roomsAvailables,
                                        ratesList: rateplan.ratesList,
                                        rate_config_rules:[rateplanModel]
                                    }
                                    if(rateplan.isModified){
                                        rateplan.roomsAvailables = await this.generateRateList(rateplan)
                                        console.log('rateplan.roomsAvailables', rateplan.roomsAvailables);
                                        rateplanArrays.push(rateplanBase);
                                    }
                                }
                                rateplan.rateplan_links.forEach(rateplanLink=>{
                                    if(rateplanLink.isModified){
                                        let findRateplan = rateplansEdit.find(item=> item._id === rateplanLink._id);
                                        if(findRateplan){
                                            rateplanArrays.push(findRateplan);
                                        }else{
                                            let rateplanModel = {
                                                rate_config_id: null,
                                                index: 0,
                                                configList: [],
                                                conditions: [
                                                    {
                                                        editable: true,
                                                        room_nights: '',
                                                        rate_type: 'Manual',
                                                        price: ''
                                                    }
                                                ]
                                            };
                                            rateplanLink.roomsAvailables.map(item=> item.ratesList = rateplanLink.ratesList);

                                            let rateplanBase = {
                                                name: rateplanLink.name,
                                                _id: rateplanLink._id,
                                                roomsAvailables: rateplanLink.roomsAvailables,
                                                ratesList: rateplanLink.ratesList,
                                                rate_config_rules:[rateplanModel]
                                            }
                                            rateplanArrays.push(rateplanBase);
                                        }
                                    }
                                });
                                keyIdx++;
                            }


                            /*this.info_page.rateplans.forEach( async rateplan=>{



                            })*/
                            //console.log('rate_config_rules', rateplanArrays);
                            this.revenue.rateplans = rateplanArrays;

                        }

                        this.closeLoading()
                        break;

                    case 'page_5':
                        if(this.info_page.buttons.description){
                            this.info_page.index_page=5;
                        }

                        break;

                }

            },
            async generateRateList(rateplan){
                let parameters = {
                    rateplan: rateplan._id
                }

                console.log('rateplan room_links', rateplan);
                let ratesList = rateplan.ratesList;
               //console.log('rateplan_list_base');
               //console.log(ratesList);
                let dataRateconfigsDB = [];

                if(rateplan.room_links) {
                    let rateConfigs = await contractService.getRateConfig(parameters)
                    dataRateconfigsDB = rateConfigs.data.data.rateconfigs;
                    /*let modelValue = {};
                    modelValue.reset_config = false;**/
                }



                rateplan.roomsAvailables.forEach(room => {
                    let rateplan_list =  JSON.parse(JSON.stringify(ratesList))
                    room.ratesList = rateplan_list;
                    console.log('rateplan room_links', rateplan.room_links);
                    if(rateplan.room_links) {
                        let getInfo = dataRateconfigsDB.find(item=> item.room === room._id)
                        if(getInfo.room_link!==undefined && getInfo.room_link!==null){
                            room.ratesList.map(element => element.value = rateplanFuntions.gapOnly(element.value, getInfo.base_rate, getInfo.room_link_type)) ;
                            console.log('getInfo room link', room.ratesList);
                        }
                    }
                })


                return rateplan.roomsAvailables;

            },
            stepsValidate(steps){
                let responseStatus = true;

                let arrayAux = [];


                switch (steps) {


                    case 'type':
                        break;

                    case 'name':
                        arrayAux.push(this.revenue.name)
                        if(this.validadorCustom(arrayAux, 'string')){
                            responseStatus = false;
                        }
                        this.info_page.buttons.name = responseStatus;

                        break;

                    case 'rateplans':
                        let count_rateplan = false;
                        //console.log('count_rateplan 1 ', count_rateplan)
                        if(!count_rateplan){
                            count_rateplan = this.info_page.rateplans.some(rateplan=>  rateplan.isModified === true)
                        }
                        //console.log('count_rateplan 2', count_rateplan)
                        if(!count_rateplan){
                            count_rateplan = this.info_page.rateplans.some(rateplan=>  rateplan.rateplan_links.some(rateplanLink => rateplanLink.isModified === true) === true)
                        }
                        //console.log('count_rateplan 3', count_rateplan)

                        this.info_page.buttons.rateplans = count_rateplan;
                        break;
                }

            },
            addRoom(idxKey){

                //this.checkDifference(null, 'change');
                let roomModel = {
                    rate_config_id: null,
                    index:  this.revenue.rateplans[idxKey].rate_config_rules.length,
                    configList: [],
                    conditions: [
                        {
                            editable: true,
                            room_nights: '',
                            rate_type: this.revenue.rateplans[idxKey].ratesList.length>1?null:'Manual',
                            price: ''
                        }
                    ]
                }

                this.revenue.rateplans[idxKey].rate_config_rules.push(roomModel)
                this.checkDifferences()
            },

            addCondition(idxRateplan, idxRoom){

                this.revenue.rateplans[idxRateplan].rate_config_rules[idxRoom].conditions.push({
                    editable: true,
                    room_nights: '',
                    rate_type: this.revenue.rateplans[idxRateplan].ratesList.length>1?null:'Manual',
                    price: ''
                })
            },
            deleteCondition(rateplanN, roomN, n){
                //console.log('et.v', rateplanN, roomN, n);
                this.revenue.rateplans[rateplanN].rate_config_rules[roomN].conditions.splice(n, 1)

                //this.checkDifference();
            },

            deleteRoom(rateplanN, n){
                //console.log('et.v', rateplanN, n);
                this.revenue.rateplans[rateplanN].rate_config_rules.splice(n, 1)

                this.checkDifferences()
                //this.checkDifference();
            },
            changeBar(event, condition, ratesList){

                let priceSelected = ratesList.find(item => item.name === event);
                if(priceSelected){
                    priceSelected = priceSelected.value;
                }else{
                    priceSelected = condition.price;
                }
                if(event ==='Manual'){
                    condition.editable = true;
                }else{
                    condition.editable = false;
                    condition.price = priceSelected
                }
            },

            checkDifferences(){
                //console.log('this.revenue', this.revenue);
                this.revenue.rateplans.map(rateplan => rateplan.roomsAvailables.map(room=> room.status = !(rateplan.rate_config_rules.some(config_rule => config_rule.rate_config_id === room.rate_config_id))));

            },

            changeRoom(event, roomsAvailables, config_rule){
                let room_finded = roomsAvailables.find(item => item.rate_config_id === event)
               //console.log('changeRoom', event, room_finded)

                config_rule.configList = room_finded.ratesList;

            },

            changeTypeFilter(type_, value_ = null, status_is = false) {
                //console.log('type', type_, value_, status_is);
                let all_t = this.info_page.all_types;
                //let rooms = this.inventory.rooms
                //let filter_options = this.info_page.filter_options
                let contract_types = this.info_page.contract_types;
                if(type_==='item'){
                    let rateplansFilters = this.info_page.rateplans.filter(item=>item.meal_plan === value_);
                   //console.log('rateplansFilters', rateplansFilters);
                    rateplansFilters.map(element=> element.isModified  = status_is)
                    rateplansFilters.map(element=> element.rateplan_links.map(rateplanLink=> rateplanLink.isModified = status_is))
                }


                /*this.info_page.rateplans.forEach(item=>{
                    if(item.meal_plan === value_){
                        item.isModified = status_is;
                    }
                    item.rateplan_links.forEach(itemLink=>{
                        if(itemLink.meal_plan === value_){
                            itemLink.isModified = status_is;
                        }
                    })
                })*/

                if (type_ === 'all') {

                    let status_is = this.info_page.all_types;
                   //console.log('all', status_is);
                    this.info_page.contract_types.map(item=> item.filter = status_is);
                    let rateplansFilters = this.info_page.rateplans;
                    rateplansFilters.map(element=> element.isModified  = status_is)
                    rateplansFilters.map(element=> element.rateplan_links.map(rateplanLink=> rateplanLink.isModified = status_is))
                }
                this.stepsValidate('rateplans')

            },

            initLoading(text = 'Cargando...') {

                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },
        mounted() {
            this.changeProperty()
            //this.$refs.topProgress.start();
        }
    }
</script>
<style lang="scss">
    .lh-40 {
        line-height: 40px;
    }
    .mt-25{
        margin-top: 32px;

    }

    .box-inventory {
        border: 0;
        .is_title {
            font-size: 16px;
            margin: 0 0 15px;
        }
        .subtitle {
            font-weight: 600;
            margin-bottom: 25px;
        }

        .box-button {
            //padding-left: 10px;
            .add-item {
                width: 100%;
                height: 40px;
                color: #fff;
                background-color: #222222;
                border: 2px solid #000;
                &.inactive {
                    opacity: .2;
                    cursor: default;
                }
            }
        }
        .form-updates {
            .head {

                label {
                    font-size: 14px;
                    margin-bottom: 30px;
                    color: #666;
                    &.subtitle {
                        margin-bottom: 0;
                        font-size: 16px;
                        color: #000;
                    }
                }
            }
            .group-upd {
                margin-top: 15px;
                display: inline-block;
                label {
                    font-weight: 600;
                    margin-bottom: 0;
                }
                span {
                    font-weight: 400;
                    margin-bottom: 10px;
                    display: inline-block;
                    color: #666;
                    font-size: 14px;
                }
                .select-default {
                    display: inline-block;
                    width: calc(50% - 5px);
                    float: left;
                    margin-left: 5px;
                    .el-input {
                        input {
                            padding-left: 10px;
                            padding-right: 30px;
                        }
                    }
                }

                .input-default {
                    display: inline-block;
                    width: calc(50% - 5px);
                    margin-right: 5px;
                    float: left;
                }

            }
        }
        .form-dates {

            .box-dates {
                .form-check {
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            .dates-manually {
                width: 100%;
                .box-calendar {
                    width: 100%;
                }
                .box-legends {
                    width: 100%;
                    padding: 15px 0;
                    display: inline-block;
                    label {
                        width: 50%;
                        float: left;
                        display: inline-block;
                        margin-bottom: 0;
                        &.color-blue {
                            color: #3B4FBF;
                        }
                        &.right-t {
                            text-align: right;
                        }
                        .example-picker {
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            background: #3B4FBF;
                            margin-right: 7px;
                            border-radius: 15px;
                        }
                    }

                }
            }
        }

        .w-border {
            border: 2px solid #bebebe;
            padding: 10px 25px;
            margin-bottom: 15px;
        }
        .form-preview {
            position: relative;
            &.form-resume{
                label{
                    float: left;
                }
                .p-contain {
                    float: left;
                }
                .brl{
                    float: left;
                }
            }
            .l-title {
                font-weight: 600;
            }
            .text-normal {
                font-size: 14px;
            }
            .brl {
                display: inline-block;
                width: 100%;
            }
            .a-right {
                position: absolute;
                right: 50px;
                top: 0;
                bottom: 0;
                left: auto;
                margin: auto;
            }
            .type-button {
                width: 45px;
                height: 45px;
                color: #ffffff;
                text-align: center;
                border-radius: 100px;
                cursor: pointer;
                i {

                    font-size: 25px;
                    line-height: 45px;
                    left: -3px;
                    position: relative;
                }
                &:hover {
                    color: #fff;
                }
            }
            .type-button {
                right: 120px;
            }
            .type-text {
                height: 45px;
                line-height: 45px;
                cursor: pointer;
            }
            .p-contain {
                width: calc(100% - 40%);
                position: relative;
                display: inline-block;
                border-bottom: 1px solid;
                margin-bottom: 5px;
                .s-left {
                    display: inline-block;
                    width: 50%;
                }
                .s-right {
                    text-align: right;
                    display: inline-block;
                    width: 50%;
                }
            }

        }
        .form-send {
            .s-icon {
                font-size: 20px;
                color: #bf9000;
                float: left;
            }
            label {
                display: inline-block;
                color: #bf9000;
                font-size: 14px;
                width: auto;
                margin-left: 10px;
            }
            .line-complete{
                label{
                    width: 90%;
                }
            }
            .box-button {
                button {
                    width: calc(33.3% - 30px);
                    margin-right: 15px;
                }
            }

        }

        .box-top {
            padding: 25px;
            border: 2px solid #eeeeee;
            margin-bottom: 15px;
        }
        label {
            width: 100%;
            display: inline-block;
            margin-bottom: 15px;
            font-size: 16px;
            text-transform: unset;
            span {
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont {
                margin-left: 0;
            }

        }
        h6 {
            margin-bottom: 15px;
            color: #085394;
            font-size: 18px;
            text-transform: unset;
        }
        .box-calendar {
            .item-month {

                .calendar-title {
                    font-size: 17px;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    //width: 60%;
                    &.w-80 {
                        width: 60%;
                    }
                    &.ml-20 {
                        margin-left: 20%;
                    }
                }
                .sp-icon {
                    width: 20%;
                    text-align: center;
                    &.n-visible {
                        visibility: hidden;
                    }
                }
                display: inline-block;
                width: 30%;
                float: left;
                &:first-child {
                    margin-right: 5%;
                }
                &:last-child {
                    margin-left: 5%;
                }
                .line-week {
                    width: 100%;
                    display: inline-block;
                    &.lw-head {
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    .item-7 {
                        cursor: pointer;
                        display: inline-block;
                        width: calc(100% / 7);
                        text-align: center;
                        height: 25px;
                        line-height: 25px;
                        float: left;
                        .select-day {
                            background: #3B4FBF;
                            width: 25px;
                            display: inline-block;
                            border-radius: 100px;
                            color: #fff;
                        }

                        .block-day {
                            background: #E1D8E9;
                            width: 100%;
                            display: inline-block;
                            color: #000;
                            cursor: default;
                        }
                    }
                }
            }
        }


        .rate-1-3 {
            width: 33%;
            display: inline-block;
            float: left;
        }
        .rate-2-3 {
            width: 66%;
            display: inline-block;
            float: left;
        }





        .item-form {

            &.mid {
                .el-select {
                    width: calc(50% - 15px);
                }
                .input-default {
                    width: calc(50% - 15px);
                }
            }
            &.dob {
                .el-select {
                    width: calc(50% - 10px);
                    margin: 0 10px 0 0;
                    .el-input {
                        input {
                            padding-left: 10px;
                            padding-right: 20px;
                            font-size: 12px;
                        }
                    }
                }
                .input-default {
                    width: calc(50% - 10px);
                    margin: 0 10px 0 0;
                }
            }
            .el-select {
                width: calc(100% - 15px);
                margin: 0 15px 0 0;
                float: left;
            }
            .input-default {
                width: calc(100% - 15px);
                margin: 0 15px 0 0;
                float: left;
            }
        }
        div.box-ai{
            .item-form {

                width: 50%;
                display: inline-block;
                .el-select {
                    width: calc(100% - 15px);
                    margin: 0 5px 0 0;
                    float: left;
                }
                .input-default {
                    width: calc(100% - 5px);
                    margin: 0 5px 0 0;
                    float: left;
                }
            }

        }

        .rate-1-5 {
            width: 15%;
            display: inline-block;
            float: left;
            &.dob {
                width: 20%;
            }
        }
        .rate-1-4 {
            width: 20%;
            display: inline-block;
            float: left;
        }
        .h-section {
            margin: 25px 0 0;
            display: block;
        }
        .box-head, .box-rate, .box-restrict,  .box-body {
            width: 100%;
            display: inline-block;
            float: left;
        }
        .box-body{
            &.pt-40{
                padding-top: 30px;

            }
            .row-css{
                margin-bottom: 20px;
                padding: 0 0 0 20px;
            }
        }
        .box-ai{
            label{
                float: left;
            }
            .item-form{

                float: left;
            }

        }
        .box-head {
            margin-top: 15px;
            label {
                font-size: 14px;
                margin-bottom: 0;
            }
        }
        .box-item {
            display: inline-block;
            width: 100%;
        }

        .form-check .form-check-label {
            text-transform: none;
        }
    }
    .item-info{
        width: 100%;
        margin-bottom: 10px;
        .room-info{
            width: 25%;
            display: inline-block;
            .item-minus{
                display: inline-block;
            }
        }
        .condition-info{
            display: inline-block;
            width: 75%;
            white-space: nowrap;
            .item-condition{
                position: relative;
                width: 32%;
                display: inline-block;
                margin: 0 2px;
                border: 2px solid #bebebe;
                padding: 10px 3px 5px;

                &.empty{
                    cursor: pointer;
                    height: 80px;
                    vertical-align: top;
                    text-align: center;
                    font-size: 18px;
                    span{
                        line-height: 60px;
                    }
                    i{
                        font-size: 22px;
                        margin-left: 10px;
                    }
                    .btn-plus{
                        top: 3px;
                        position: relative;
                    }
                }
                .inside-condition{
                    width: 36%;
                    display: inline-block;
                    padding: 0 2px;
                    label{
                        display: block;
                        margin: 0;
                        font-size: 13px;
                        margin-bottom: 2px;
                    }
                    &.short{
                        width: 28%;
                    }

                }
                .input-default, .select-default{
                    width: 100%;
                    display: inline-block;
                    margin-bottom: 0;
                }
                .select-default.el-select .el-input input{
                    padding: 0 15px 0 10px;
                }
                .el-input__suffix{
                    right: 0;
                }
            }
        }

    }
    .btn-corner{

        top: 0px;
        right: 0px;
        position: absolute;
        font-size: 20px;

    }
    .add-text{
        color: #1D62F0;
        display: inline-block;
        font-size: 15px;
        font-weight: 400;
    }



    .select-default {
        &.hasError {
            border: 1px solid #F24957;
        }
    }

    .input-default {
        &.hasError {
            input {
                border: 1px solid #F24957;
            }
        }
    }
    .c-point{
        cursor: pointer;
    }
    .el-select.class-disabled .el-input.is-disabled .el-input__inner{
        background-color: #F5F5F5;
    }

</style>
